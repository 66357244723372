@import "primeng/resources/themes/aura-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";


body{
  margin: 0;
  padding: 0;
}

main{
  padding: 0.5rem;
}

th,
td {
  text-align: center;
  color: #000000;
}

a {
  text-decoration: none;
}

.p-dropdown,
.p-inputnumber,
.p-calendar {
  width: 100% !important;
}

p-autocomplete > input .p-element.p-autocomplete-input {
  width: 100% !important;
}